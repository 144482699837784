<template>
  <div class="page-sys-introduction">
    <item-title :title="{ name: '系统介绍', en_1: 'SYSTEM', en_2: 'INTRODUCTION', size: 'big' }" />
    <div class="sys-introduction">
      <table>
        <tr class="hd">
          <th>应用名称</th>
          <th>图标</th>
          <th>简介</th>
        </tr>
        <tr>
          <td>明亚经纪</td>
          <td><img src="@/assets/img/disclosure/logo-mingya.png" alt=""></td>
          <td>
            明亚经纪主要为保险从业人员提供服务，是一款免费的展业工具。为保险从业人员提供在线制作计划书、在线投保、产品查询、产品对比、客户管理、保单管理、绩效查询等功能。协助保险从业人员轻松展业。
          </td>
        </tr>
        <tr>
          <td>明亚双录</td>
          <td><img src="@/assets/img/disclosure/logo-shuanglu.png" alt=""></td>
          <td>明亚双录主要为保险从业人员提供双录服务，是一款免费的工具。收录了各家合作保险公司的双录系统信息，为保险从业人员提供保司双录操作说明，协助保险从业人员轻松操作双录任务。</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import ItemTitle from '@/components/common/itemTitle'
export default {
  components: {
    ItemTitle,
  },
  data() {
    return {}
  },
  mounted() {
    this.$emit('loading', false)
  },
  created() {},
  methods: {},
}
</script>

<style lang="scss" scoped>
.sys-introduction {
  table {
    margin-top: 0.3rem;
    width: 100%;
    border-top-left-radius: 0.16rem;
    border-top-right-radius: 0.16rem;
    overflow: hidden;
    tr {
      border: 1px solid #ebebeb;
      td {
        height: 2rem;
        padding: 0.1rem 0.2rem;
        border-right: 1px solid #ebebeb;
        text-align: center;
        font-size: 0.18rem;
        &:nth-child(3) {
          text-align: left;
        }
        img {
          width: 1.1rem;
          height: 1.1rem;
        }
      }
      
      th {
        border-right: 1px solid #ebebeb;
        padding: 0 0.08rem;
        font-weight: 400;
        font-size: 0.2rem;
      }
    }
    .hd {
      background-color: #fe9022;
      height: 0.7rem;
      line-height: 0.7rem;
      text-align: center;
      width: 100%;
      th {
        border: 0;
        text-align: center;
        color: #ffffff;
        position: relative;
        padding: 0 0.04rem;
        line-height: 1.4;
        &::after {
          content: '';
          width: 1px;
          height: 0.22rem;
          background-color: #ffffff;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
        text-align: center;
        &:nth-of-type(1) {
          width: 25%;
        }
        &:nth-of-type(2) {
          width: 25%;
        }
        &:last-child {
          width: 50%;
          &::after {
            content: '';
            width: 1px;
            height: 0.22rem;
            background-color: #ffffff;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            display: none;
          }
        }
      }
    }
  }

  @media only screen and (max-width: $moble_width) {
    .modal .content {
      width: 80%;
    }
  }
}
</style>
